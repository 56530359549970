<template>
  <div class="mycontent">
    <van-collapse v-model="activeNames">
      <!-- <van-collapse-item title="登记证信息" name="1">内容</van-collapse-item> -->
      <!-- <van-collapse-item title="登记证信息" value="1243" name="1" icon="shop-o"> -->
      <van-collapse-item title="登记证信息" name="1" icon="shop-o">
        <ul class="list-group">
          <li class="list-group-item">
            <span>农药产品名称：</span
            ><span id="registName">{{ dataRes.registName }}</span>
          </li>
          <li class="list-group-item">
            <span>农药登记证号：</span
            ><span id="registNo">{{ dataRes.registNo }}</span>
          </li>
          <li class="list-group-item">
            <span>登记证持有人：</span
            ><span id="registCompany">{{ dataRes.registCompany }}</span>
          </li>
          <li class="list-group-item">
            <span>编码：</span><span id="code">{{ dataRes.code }}</span>
          </li>
          <li class="list-group-item">
            <span>追溯网址：</span
            ><a id="traceUrl" :href="dataRes.traceUrl">{{
              dataRes.traceUrl
            }}</a>
          </li>
        </ul>
      </van-collapse-item>
      <!-- <van-collapse-item title="商标规格信息" name="2">内容</van-collapse-item> -->
      <van-collapse-item name="2">
        <template #title>
          <div><van-icon name="hot-o" />商标规格信息</div>
        </template>
        <ul class="list-group">
          <li class="list-group-item">
            <span>规格名称：</span
            ><span id="specName">{{ dataRes.specName }}</span>
          </li>
          <li class="list-group-item">
            <span>商标名称：</span
            ><span id="tagname">{{ dataRes.tagSubName }}</span>
          </li>
        </ul>
      </van-collapse-item>
      <van-collapse-item title="生产质检信息" name="3" icon="font-o">
        <ul class="list-group">
          <li class="list-group-item">
            <span>生产批次：</span
            ><span id="batchNo">{{ dataRes.batchNo }}</span>
          </li>
          <li class="list-group-item">
            <span>生产日期：</span
            ><span id="produceDate">{{ dataRes.batchDate }}</span>
          </li>
          <!-- <li class="list-group-item"><span>有效期至：</span><span id="failDate">{{dataRes.failDate}}</span></li> -->
          <li class="list-group-item">
            <span>质检员：</span
            ><span id="checkPeople">{{ dataRes.checkPeople }}</span>
          </li>
          <!-- <li class="list-group-item"><span>质检日期：</span><span id="checkDate"></span></li> -->
          <li class="list-group-item">
            <span>质检结果：</span
            ><span id="checkResult">{{ dataRes.checkResult }}</span>
          </li>
          <li class="list-group-item">
            <span>货源归属：</span><span id="owner">{{ dataRes.owner }}</span>
          </li>
          <li class="list-group-item" v-if="dataRes.ownerLine != undefined">
            <span>终端归属：</span
            ><span id="ownerLine">{{ dataRes.ownerLine }}</span>
          </li>
        </ul>
      </van-collapse-item>
      <van-collapse-item title="查询信息" name="4" icon="eye-o">
        <ul class="list-group">
          <li class="list-group-item">
            <span>累计查询次数：</span
            ><span id="count">{{ dataRes.count }}</span>
          </li>
          <li class="list-group-item">
            <span>上次查询时间：</span
            ><span id="time">{{ dataRes.lastTime }}</span>
          </li>
        </ul>
      </van-collapse-item>
    </van-collapse>
  </div>
</template>

<script>
export default {
  name: 'MyContent',
  props: {
    dataRes: Object
  },
  data () {
    return {
      activeNames: ['1', '2', '3'],

    }
  },
  // 进入加入背景颜色
  mounted () {
    this.showLog()
  },
  methods: {
    showLog () {
      // console.log(111,this.dataRes);
      console.log(112, this.dataRes.ownerLine)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
