<template>
  <div class="query">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <MyHeader msg="二维码追溯结果" />
    <MyContent :dataRes="dataRes" />
    <MyFooter :coperight="coperight" />

  </div>
</template>

<script>
import { findByCode } from "@/api/scan";
// @ is an alias to /src
import MyContent from '@/components/MyContent.vue'
import MyHeader from '@/components/MyHeader.vue'
import MyFooter from '@/components/MyFooter.vue'

export default {
  name: 'QueryView',
  components: {
    MyContent,
    MyHeader,
    MyFooter
  },
  data() {
    return {
      // coperight: '广西田园生化股份有限公司',
      coperight: '',
      dataRes: {
        // registName:undefined,
        // registNo:null,
        // registCompany:null,
        // registName:null,
        // registName:null,
        // registName:null,
        // registName:null,
        // registName:null,
        // registName:null,
        // registName:null,
      },
      code: ""
    }
  },

  created() {
    this.onSubmit();
  },

  methods: {
    onSubmit() {
      this.code = this.$route.query.c;
      console.log("路径的code", this.code);
      findByCode(this.code).then(response => {
        // console.log(11,response.data);
        // console.log(12,response.code);
        if (response.code == 200) {
          this.dataRes = response.data;
        } else {
          // this.$toast('编码不存在……');
          // 危险通知
          this.$notify('编码不存在……');
          // this.$notify({ type: 'warning', message: '编码不存在……' });
        }

        this.coperight = this.dataRes.produceCompany;//动态更改网页版权不合适，管局会查，有风险
      }
      );

      // console.log("提交触发");
    },
  }
}
</script>
