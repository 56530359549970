<template>
  <div class="myheader">
    <h3>{{ msg }}</h3>
  </div>
</template>

<script>
export default {
  name: 'MyHeader',
  props: {
    msg: String
  },
  data() {
    return {
      activeNames: ['1', '2']
    }
  },
  created(){
    this.showLog()
  },
  methods:{
    showLog(){
      // console.log(222,this.msg);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h2 {
  margin: 2px 0 10px 0;
  padding: 10px 0 10px 0;
  color: #ffffff;
  background-color: #f8ac59;
}

h3 {
  margin: 2px 0 10px 0;
  padding: 10px 0 10px 0;
  color: #ffffff;
  background-color: #f8ac59;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
