<template>
  <div class="home">

    <MyHeader msg="二维码追溯结果" />
    <!-- <van-button type="primary">主要按钮</van-button> -->

    <!-- <van-form @submit="onSubmit">
      <h4>请输入32位单元识别码查询：</h4>

      <van-search v-model="value" show-action label="" left-icon="qr" placeholder="扫描二维码或输入32位编码" @search="onSearch">
        <template #action>
          <div @click="onScan">
            <van-icon dot color="#1989fa" size="26px" name="scan" />
          </div>
        </template>
      </van-search>

      <van-row type="flex" justify="center">
        <van-col>
          <van-button type="warning"  size="small" native-type="submit" color="#f8ac59"  >立即提交</van-button>
        </van-col>
        <van-col>
          <van-button plain type="default" size="small" native-type="button" @click="onReset" >重置</van-button>
        </van-col>
      </van-row>
    </van-form> -->
    <div style="padding: 0 0 10px 0">
      <!-- <h4>请输入32位单元识别码查询：</h4> -->
      <span>请输入32位单元识别码查询：</span>

      <van-search v-model="code" show-action label="" left-icon="qr" placeholder="扫描二维码或输入32位编码" @search="onSearch">
        <template #action>
          <div @click="onScan" style="padding: 10px 0 0 0">
            <van-icon dot color="#1989fa" size="26px" name="scan" />
          </div>
        </template>
      </van-search>

      <van-row type="flex" justify="center">
        <van-col>
          <van-button type="warning" size="small" @click="onSubmit" color="#f8ac59">立即提交</van-button>
        </van-col>
        <van-col>
          <van-button plain type="default" size="small" @click="onReset">重置</van-button>
        </van-col>
      </van-row>
    </div>
    <MyContent :dataRes="dataRes" />
    <MyFooter :coperight="coperight" />
  </div>

</template>

<script>

import { findByCode } from "@/api/scan";

// @ is an alias to /src
import MyContent from '@/components/MyContent.vue'
import MyHeader from '@/components/MyHeader.vue'
import MyFooter from '@/components/MyFooter.vue'


export default {
  name: 'HomeView',
  components: {
    MyContent,
    MyHeader,
    MyFooter
  },
  data() {
    return {
      code: '',
      // coperight: '广西田园生化股份有限公司',
      coperight: '',
      // dataRes: {a:"123",b:123},
      dataRes: {},
    }
  },  
  methods: {


    onSearch(val) {
      // Toast(val);
      console.log("搜索触发");
    },
    onScan(val) {
      // Toast(val);
      this.$toast('正在开发中……');
      console.log("打开摄像头");
      // this.$toast.loading({
      //   message: '加载中...',
      //   forbidClick: true,
      // });
    },


    onSubmit() {
      console.log("输入框的值：", this.code);
      findByCode(this.code).then(response => {
        // this.dataRes = response.data;
        if (response.code == 200) {
          this.dataRes = response.data;
        } else {
          // this.$toast('编码不存在……');
          // 危险通知
          this.$notify('编码不存在……');
          // this.$notify({ type: 'warning', message: '编码不存在……' });
        }


        this.coperight = this.dataRes.produceCompany;//动态更改网页版权不合适，管局会查，有风险
      }
      );

      console.log("提交触发");
    },
    onReset() {
      this.code = "";
      console.log("重置");
    },
  },
}
</script>

<style lang="scss"  scoped>
// .about {
//   .van-form {
//     .van-row {
//       .van-col {
//         margin-left: 10px;
//       }
//     }
//   }
// }
.home {
  .van-row {
    .van-col {
      margin-left: 10px;
    }
  }
}
</style>
