import axios from "axios"
// import store from "@/store";

// 创建axios实例
const service = axios.create({
  baseURL: "https://qr.tyyzb.cn:881", // api 的 base_url  正式使用
  // baseURL: "http://localhost:881",
  // baseURL: "http://192.168.43.143:881",
  // baseURL: '/dev-api',
  // baseURL: process.env.BASE_API, 
  // baseURL: "/api",  //本地测试使用
  timeout: 10000 // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
  config => {
    // if (getToken()) {
    //   config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    // }
    config.headers['Content-Type'] = 'application/json'
    return config
  },
  error => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
    const code = response.status
    // console.log(111,response)
    if (code < 200 || code > 300) {
      Notification.error({
        title: response.message
      })
      return Promise.reject('error')
    } else {
      return response.data
    }
  },
  error => {
    let code = 0
    try {
      code = error.response.data.status
    } catch (e) {
      if (error.toString().indexOf('Error: timeout') !== -1) {
        Notification.error({
          title: '网络请求超时',
          duration: 2500
        })
        return Promise.reject(error)
      }
      if (error.toString().indexOf('Error: Network Error') !== -1) {
        Notification.error({
          title: '网络请求错误',
          duration: 2500
        })
        return Promise.reject(error)
      }
    }
    // if (code === 401) {
    //   MessageBox.confirm(
    //     '登录状态已过期，您可以继续留在该页面，或者重新登录',
    //     '系统提示',
    //     {
    //       confirmButtonText: '重新登录',
    //       cancelButtonText: '取消',
    //       type: 'warning'
    //     }
    //   ).then(() => {
    //     store.dispatch('LogOut').then(() => {
    //       location.reload() // 为了重新实例化vue-router对象 避免bug
    //     })
    //   })
    // } else if (code === 403) {
    //   router.push({ path: '/401' })
    // } else {
    //   const errorMsg = error.response.data.message
    //   if (errorMsg !== undefined) {
    //     Notification.error({
    //       title: errorMsg,
    //       duration: 2500
    //     })
    //   }
    // }
    return Promise.reject(error)
  }
)
export default service
