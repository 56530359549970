<template>
  <div class="contentDiv">
    <div class="infoDiv">
      <div class="infoValue">
        <span>{{ prefix }}</span>
        <span class="moneySpan">{{ info }}</span>
        <span>{{ suffix }}</span>
      </div>
      <div><img :src="logo" alt="" width="250" height="250" /></div>
    </div>
  </div>
</template>

<script>
import logo from "@/assets/logo2.png"
// @ is an alias to /src

export default {
  name: 'TurnView',
  components: {
  },
  data () {
    return {
      // info: "204503220233338",
      logo,
      info: "",
      money: "",
      prefix: "",
      suffix: "",
      redpackCodes: ['11824881003100166121640311804181', '11824881003100169271640311804963', '11824881003100164961640311804002', '11824881003100162511640311804628', '11824881003100161841640311804840', '11824881003100109201640311804150']
    }
  },

  created () {
    this.onSubmit()
  },

  methods: {
    onSubmit () {
      var money = this.$route.query.m
      if (money == 666) {
        this.prefix = "恭喜您获得"
        this.info = 666
        this.suffix = "元红包，请找产品经理兑奖!"
      } else {
        this.prefix = "恭喜您获得"
        this.info = 88
        this.suffix = "元红包，点击红包即可领取！"
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.contentDiv {
  width: 100vw;
  height: 100vh;
  background-color: #f8ac59;
}
.infoDiv {
  top: 20px;
  height: 100%;
  color: white;
  font-size: 26px;
  font-weight: 26px;
  text-align: center;
}
.infoValue {
  padding-top: 100px;
  margin-bottom: 50px;
}

.moneySpan {
  font-size: 40px;
  color: red;
  margin: 0 10px;
}
</style>

