import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import QueryView from '../views/QueryView.vue'
import TurnView from '../views/TurnView.vue'
import RedpackView from '../views/RedpackView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  // {
  //   path: '/q/:q.do*',
  //   // path: '/q/',
  //   name: 'query',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "query" */ '../views/QueryView.vue')
  // },
  {
    path: '/q/:q.do*',
    name: 'query',
    component: TurnView
  },
  {
    // path: '/q/:q.do*',
    path: '/q/query',
    name: 'query2',
    component: QueryView
  },
  {
    path: '/q/redpack',
    name: 'redpack',
    component: RedpackView
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
