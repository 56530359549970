import request from "@/utils/request"

export function findByCode (code) {
  return request({
    url: "/q/query?code=" + code,
    // url: "/ewm-scan/q/query?code=" + code,
    method: "get",
  })
}

export function checkByCode (code) {
  return request({
    url: "/q/query2?code=" + code,
    // url: "/ewm-scan/q/query?code=" + code,
    method: "get",
  })
}
