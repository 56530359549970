<template>
  <div class="contentDiv">
    <div class="infoDiv">
      <div class="infoValue">{{ info }}</div>
    </div>
  </div>
</template>

<script>
import { checkByCode } from "@/api/scan"
// @ is an alias to /src

export default {
  name: 'TurnView',
  components: {
  },
  data () {
    return {
      // info: "204503220233338",
      info: "",
      code: "",
      redpackCodes: ['11824881003100169271640311804963', '11824881003100164961640311804002', '11824881003100162511640311804628', '11824881003100161841640311804840', '11824881003100109201640311804150']
    }
  },

  created () {
    this.onSubmit()
  },

  methods: {
    onSubmit () {
      this.code = this.$route.query.c
      //TODO 李若飞红包
      if (this.redpackCodes.includes(this.$route.query.c)) {
        // this.$router.push('/q/query?c=' + this.code)
        if (this.$route.query.c === '11824881003100169271640311804963') {
          this.$router.push('/q/redpack?m=666')
        } else {
          this.$router.push('/q/redpack?m=88')
        }
      } else {
        checkByCode(this.code).then(response => {
          if (response.code == 200) {
            var res = response.data
            if (res.result) {
              var info = res.data
              if (info !== "" || info !== null) {
                this.info = info
              } else {
                this.info = "00000000"
              }
            } else {
              this.$router.push('/q/query?c=' + this.code)
            }
          } else {
            // 危险通知
            this.$notify('编码不存在……')
            this.info = "00000000"
          }
        }
        )
      }
      // checkByCode(this.code).then(response => {
      //   if (response.code == 200) {
      //     var res = response.data
      //     if (res.result) {
      //       var info = res.data
      //       if (info !== "" || info !== null) {
      //         this.info = info
      //       } else {
      //         this.info = "00000000"
      //       }
      //     } else {
      //       this.$router.push('/q/query?c=' + this.code)
      //     }
      //   } else {
      //     // 危险通知
      //     this.$notify('编码不存在……')
      //     this.info = "00000000"
      //   }
      // }
      // )

    },
  }
}
</script>
<style lang="scss" scoped>
.contentDiv {
  width: 100vw;
  height: 100vh;
  background-color: #f8ac59;
}
.infoDiv {
  top: 20px;
  height: 100%;
  color: white;
  font-size: 26px;
  font-weight: 26px;
  text-align: center;
}
.infoValue {
  padding-top: 100px;
}
</style>

