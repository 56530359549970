<template>
  <div class="myfooter">
    <div style="text-align: center">
      <hr/>
      <span>Copyright © 2001-</span><span>{{fullYear}}</span><span id="coperight">{{coperight}}</span><span> All Rights Reserved.</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MyFooter',
  props: {
    coperight: String
  },
  data() {
    return {
      fullYear: 2022
    }
  },
  // created(){
  //   this.fullYear = this.getFullYear();
  // },
  mounted(){
    this.fullYear = this.getFullYear();
  },
  methods:{
    getFullYear() {
        var mydate = new Date();
        var str = "" + mydate.getFullYear();
        // console.log(1001,str);
        // console.log(1002,this.coperight);
        // str += (mydate.getMonth()+1) + "月";
        // str += mydate.getDate() + "日";
        return str;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h2 {
  margin: 2px 0 10px 0;
  padding: 10px 0 10px 0;
  color: #ffffff;
  background-color: #f8ac59;
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
